<template>
  <v-row
    no-gutters
    class="my-0 py-0 mx-2" align-content="center" style="max-width: 50px !important;">
    <v-col cols="12" class="my-0 py-0 text-center">
      <v-btn
        @click="toggleTransportMode"
        icon
        elevation="2"
        class="my-0 py-0"
        :style="btnStyle">
        <v-img
          v-if="enabled"
          :src="iconEnabledURL"
          alt=""
          contain
          style="height: 22px !important; width: 22px !important;"/>
        <v-img
          v-else
          :src="iconDisabledURL"
          alt=""
          contain
          style="height: 22px !important; width: 22px !important;"/>
      </v-btn>
    </v-col>
    <v-col cols="12" class="mt-1 text-center" style="white-space: nowrap;overflow-x: hidden !important; flex-wrap: nowrap !important;">
      <span style="font-size: 14px;">{{$t(`view.modes.transports.${mode.name}`)}}</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "IconMode",

  props: {
    mode: {type: Object, default: () => null}
  },

  data() {
    return {

    }
  },

  created() {
  },

  methods: {
    toggleTransportMode() {
      //console.log(!this.enabled);
      this.enabled = !this.enabled;
    }
  },

  computed: {

    iconDisabledURL() {
      return require(`@/assets/icons/modes/${this.mode.icon_disabled}`);
    },
    iconEnabledURL() {
      return require(`@/assets/icons/modes/${this.mode.icon_enabled}`);
    },

    btnStyle() {

      let style = "width: 50px !important; height: 50px !important;";

      if (this.enabled)
        return `${style} background-color: ${this.mode.color};`;

      return `${style}`;
    },

    enabled: {

      get() {
        return this.$store.getters['modes/isEnabled'](this.mode.name)
      },

      set(val) {
        this.$store.commit("modes/toggleMode", {mode: this.mode.name, value: val});
        //console.log(this.mode.name);
        if (val === true) {
          this.$store.dispatch("modes/getTransportsAround", {
            modes: [this.mode.name],
            keepOldData: true,
            jwt: this.$session.get('jwt'),
            messages: {
              ERROR_MODE_NOT_LOADED: this.$t('viewer.errors.MODES_NOT_LOADED')
            }
          });
        } else {
          this.$store.commit("modes/deleteModeFromPoints", this.mode.name);
        }
        this.$store.dispatch("modes/updateSettings", {jwt: this.$session.get("jwt"), userID: this.$session.get('id')});
      }
    }
  },
}
</script>

<style scoped>

</style>